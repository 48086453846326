@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-primary {
    color: rgb(124, 184, 228);
  }

  .border-primary {
    border-color: rgb(124, 184, 228);
  }

  .bg-primary-10\% {
    background-color: rgb(124, 184, 228, 0.1);
  }

  .no-page-break-inside {
    page-break-inside: avoid;
  }
}
